import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { MetaProps } from "~components/templates/seo";
import * as styles from "./index.module.scss";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section } from "~components/templates/section";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { DocumentStyle } from "~components/templates/document-style";
import { Button } from "~components/utils/button";

type Props = {
  data: GatsbyKenro2023NewGradsQuizSuccessPageQuery;
};

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  const meta: MetaProps = {
    title:
      "【全問正解】新卒開発エンジニアへのセキュアコーディング挑戦状 | KENRO (ケンロー) by Flatt Security",
    image: `https://flatt.tech${data?.quiz_all_succeed?.publicURL || ""}`,
    description: "セキュアコーディングの基礎知識のクイズ3問全問正解です！",
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  return (
    <Kenro2PaneLayout meta={meta}>
      <Section>
        <Common2PaneSectionLayout>
          <DocumentStyle>
            <OptionalGatsbyImage
              className={styles.Heading_Image}
              data={data.quiz_all_succeed}
              alt="全問正解"
            />
            <h2 className={styles.Heading}>
              <span>ユーザの入力値を</span>
              <span>適切に処理せずに</span>
              <span>そのままSQL文の組み立てに</span>
              <span>利用すると</span>
              <span>SQLインジェクションに</span>
              <span>つながります。</span>
            </h2>
            <p>
              SQLインジェクションは有名な脆弱性であり、基本的にはWebアプリケーションフレームワークが脆弱性の発生を防いでくれるので現代の開発者は対策に関して学ぶ機会が少ないです。しかし、開発者が自らSQL文の処理を実装する場合は現代でも存在し、そのような時に適切な実装がなされていないとSQLインジェクション脆弱性が発生し重大なリスクにつながります。
            </p>
          </DocumentStyle>
          <hr className={styles.Border} />
          <DocumentStyle>
            <OptionalGatsbyImage
              className={styles.Stickers}
              data={data.stickers}
              alt=""
            />
            <p>
              全問正解し見事このページに辿り着いたあなたにはFlatt
              Securtiyオリジナルステッカーをプレゼントします！スペシャルステッカーは先着100名の方のみ、「F」と「堅」ステッカーはフォームに申し込んだ方全員がもらえます！
              <br />
              <br />
              ご希望の方は以下のボタンよりTwitterに今回のクイズをシェアした上でGoogleフォームにご入力ください。
            </p>
          </DocumentStyle>
          <div className={styles.Share}>
            <a
              href="https://twitter.com/intent/tweet?text=新卒開発エンジニアへのセキュアコーディング挑戦状%20by%20KENRO%20%7c%20@flatt_security%20に全問正解しました！&url=https://flatt.tech/kenro/2023-new-grads-quiz"
              target="_blank"
              rel="noreferrer"
            >
              <Button buttonSize="Large" className={styles.Share_Button}>
                <OptionalGatsbyImage
                  data={data.twitter}
                  className={styles.Share_Twitter}
                  alt=""
                />
                全問正解をシェアしてステッカーをゲット！
              </Button>
            </a>
          </div>
          <div className={styles.Form}>
            <a
              href="https://forms.gle/r4RueWQ8CQoJdF7f9"
              target="_blank"
              rel="noreferrer"
            >
              <Button buttonSize="Large" buttonTheme="Kenro_Reverse">
                &#128666; Googleフォームで配送先を入力
              </Button>
            </a>
          </div>
          <DocumentStyle>
            <p>
              ※ステッカーのプレゼントは予告なく終了する可能性があります。終了した場合はフォームの解答を締め切った状態にしますので、フォームをご確認ください。
            </p>
          </DocumentStyle>
          <hr className={styles.Border} />
          <DocumentStyle>
            <p>
              今回のクイズはWebエンジニア向けセキュアコーディング学習の「KENRO」を提供する株式会社Flatt
              Securityが作成しました。
              <br />
              <br />
              「KENRO」に興味がある方は画面右側のフォーム(スマートフォンは下部)よりトライアルの登録をしてください！
            </p>
          </DocumentStyle>
        </Common2PaneSectionLayout>
      </Section>
    </Kenro2PaneLayout>
  );
};

const Container: React.FC<Props> = (props) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export const query = graphql`
  query Kenro2023NewGradsQuizSuccessPage {
    quiz_all_succeed: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/quiz_all_succeed.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    twitter: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/twitter_logo.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    stickers: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/stickers.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default Container;
